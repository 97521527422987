import { proxy, useSnapshot } from "valtio";
import { proxySet } from "valtio/utils";

import type { SortingState } from "@tanstack/react-table";

interface AlertsTableState {
  page: number;
  perPage: number;
  sorting: Readonly<SortingState>;
  selectedAlerts: Set<number>;
  detailsAlertId?: number; // alert id to show details for(if any)
  filters: {
    object_id: number[];
    severity: number[];
    created_at: Date[];
  };
}

const DEFAULT_FILTERS_STATE: AlertsTableState["filters"] = {
  object_id: [],
  severity: [],
  created_at: [],
};

const filtersState = proxy<AlertsTableState["filters"]>(DEFAULT_FILTERS_STATE);

const DEFAULT_PER_PAGE = 25;
const alertsTableState = proxy<AlertsTableState>({
  page: 0,
  perPage: DEFAULT_PER_PAGE,
  sorting: [],
  selectedAlerts: proxySet([]),
  detailsAlertId: undefined,
  filters: filtersState,
});

function updateAlertsTableStateFilterValue(
  filterName: keyof AlertsTableState["filters"],
  newValue: Date[] | number[] | string[],
) {
  if (filterName === "created_at") {
    filtersState[filterName] = newValue as Date[];
  } else {
    // arrays of numbers (object_id, severity)
    filtersState[filterName] = [...newValue].map((value) => Number(value));
  }

  if (newValue?.length > 0) {
    alertsTableState.page = 0;
  }
}

function useAlertsTableStateSnapshot() {
  return useSnapshot(alertsTableState);
}

function useAlertsTableFiltersValue() {
  return useSnapshot(filtersState);
}

function useAlertsTableFiltersValueFromAccessor(accessorKey: keyof AlertsTableState["filters"]) {
  const snapshot = useAlertsTableFiltersValue();
  return { filterValue: snapshot[accessorKey] };
}

function updateAlertsTableState(newState: Partial<AlertsTableState>) {
  Object.assign(alertsTableState, {
    page: 0, // reset page when filters change
    ...newState,
  });
}

function addIntoSelection(alertId: number) {
  alertsTableState.selectedAlerts.add(alertId);
}

function removeFromSelection(alertId: number) {
  alertsTableState.selectedAlerts.delete(alertId);
}

function clearSelection() {
  alertsTableState.selectedAlerts.clear();
}

function toggleAlertDetails(alertId?: number) {
  if (alertId && alertsTableState.detailsAlertId === alertId) {
    alertsTableState.detailsAlertId = undefined;
  } else {
    alertsTableState.detailsAlertId = alertId;
  }
}

export default alertsTableState;
export {
  filtersState,
  updateAlertsTableState,
  updateAlertsTableStateFilterValue,
  useAlertsTableFiltersValueFromAccessor,
  useAlertsTableFiltersValue,
  addIntoSelection,
  removeFromSelection,
  toggleAlertDetails,
  clearSelection,
  useAlertsTableStateSnapshot,
};
export type { AlertsTableState };
