import { proxy, useSnapshot } from "valtio";

const filtersState = proxy({
  search_value: "",
});

function useWatchedSearchesFiltersValue() {
  const filtersValue = useSnapshot(filtersState);
  return filtersValue;
}

function updateWatchedSearchesFiltersState(newState: Partial<typeof filtersState>) {
  Object.assign(filtersState, {
    ...newState,
  });
}

export default filtersState;

export { useWatchedSearchesFiltersValue, updateWatchedSearchesFiltersState };
