import { proxy, useSnapshot } from "valtio";

import { type CommonPageContext, getPageContext } from "@utils/usePageContext";

export interface DetailsPageState {
  isNotesViewOpened: boolean;
  notesPlacement: "left" | "right" | "top" | "bottom";
  cveThreatIntelId: number | null;
  notesOpenFromMention: number | null;
  isPDFExport: boolean;
}

export const detailsPageStateProxy = proxy<DetailsPageState>({
  isNotesViewOpened: false,
  notesOpenFromMention: null,
  notesPlacement: "right",
  // The current CVE Threat Intel ID opened in the threat details page, used for SPA-like navigation
  cveThreatIntelId:
    getPageContext<
      CommonPageContext & {
        threat?: { cve_threat_intel_id: number };
      }
    >().threat?.cve_threat_intel_id ?? null,
  isPDFExport: false,
});

export const useDetailsPageState = () => {
  const snap = useSnapshot(detailsPageStateProxy);
  return snap;
};

export const updateDetailsPageState = (newState: Partial<DetailsPageState>) => {
  const newStateCopy = { ...newState };
  if (!newStateCopy.notesOpenFromMention && newStateCopy.isNotesViewOpened === false) {
    // If the notes view is closed, reset the openFromMention state
    newStateCopy.notesOpenFromMention = null;

    // clean mention from URL
    const url = new URL(window.location.href);
    url.searchParams.delete("fromMention");
    window.history.replaceState({}, document.title, url.toString());
  }

  Object.assign(detailsPageStateProxy, newStateCopy);
};

export const useIsPDFExport = () => {
  return useSnapshot(detailsPageStateProxy).isPDFExport;
};