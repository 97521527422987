type Severity = "Critical" | "High" | "Medium" | "Low" | "Info";

const SEVERITY: Severity[] = ["Info", "Low", "Medium", "High", "Critical"];
const getSeverityString = (severity: number): "Critical" | "High" | "Medium" | "Low" | "Info" => {
  return SEVERITY[severity];
};
const getSeverityNumber = (severity: Severity) => {
  return SEVERITY.indexOf(severity);
};

export { getSeverityString, getSeverityNumber };
