import { w } from "windstitch";

const Link = w.a("transition-color cursor-pointer", {
  variants: {
    color: {
      primary: "text-blue-600 hover:text-blue-700",
      success: "text-green-500 hover:text-green-700",
      text: "text-gray-700 hover:text-black",
      danger: "text-red-500 hover:text-red-700",
    },
    size: {
      small: "text-xs",
      medium: "text-sm",
      large: "text-base",
    },
    font: {
      normal: "font-normal",
      semiBold: "font-semibold",
      bold: "font-bold",
    },
  },
  defaultVariants: {
    color: "primary",
    size: "medium",
    font: "normal",
  },
});

Link.displayName = "Link";

export default Link;
