import type { ThreatKey } from "@interfaces/SerializedThreat";
import { snapshot } from "valtio";

import { fieldReferencesState } from "@features/field_references/fieldReferences.state";
import type QLContext from "./QLContext";
import { escapeRegExp } from "./utils";

export interface SuggestionProps {
  // The main text for the suggestion.
  text?: string;
  // Optional text to apply on suggestion insert.
  snippetBefore?: string;
  // Optional text to apply on suggestion insert, after the cursor.
  snippetAfter?: string;
  // Alternative text for the suggestion, shown in italics, right after the main text.
  suggestionText?: string;
  type?: SuggestionType;
  // If provided, this value is used instead of the main text for insertion
  value?: string;
  // An optional CSS class for the rendered suggestion.
  className?: string;
  // Blocks any interaction with the suggestion.
  isDisabled?: boolean;
  // The textarea element to which the suggestion will be applied.
  textarea: HTMLTextAreaElement;
  // If true, this suggestion is a replacement for the whole input
  isReplacement?: boolean;
}

export type SuggestionType = "text" | "date" | "menu" | "list" | "widget";

export class Suggestion {
  text = "";
  snippetBefore = "";
  snippetAfter = "";
  suggestionText = "";
  value?: string;
  className?: string;
  isDisabled?: boolean;
  // If true, this suggestion is a replacement for the whole input
  isReplacement?: boolean;

  instance: HTMLElement | null = null;
  textarea: HTMLTextAreaElement;

  type: SuggestionType = "text";
  children?: Suggestion[];

  boundOnMouseDown: (e: MouseEvent | KeyboardEvent, context: QLContext) => void;

  constructor({
    text = "",
    snippetBefore = "",
    snippetAfter = "",
    suggestionText = "",
    value,
    className,
    type = "text",
    isDisabled = false,
    textarea,
    isReplacement = false,
  }: SuggestionProps) {
    text = Suggestion.prepareText(text as any);

    this.textarea = textarea;
    this.text = text;
    this.snippetBefore = snippetBefore;
    this.snippetAfter = snippetAfter;

    let _suggestionText = text;
    if (suggestionText) {
      _suggestionText += `<i>${suggestionText}</i>`;
    }

    this.suggestionText = _suggestionText;

    this.type = type;
    if (value) {
      this.value = value;
    }

    if (className) {
      this.className = className;
    }

    this.isDisabled = isDisabled;
    this.isReplacement = isReplacement;

    this.boundOnMouseDown = this.onMouseDown.bind(this);
  }

  // ?to replace where?
  static readonly thingsToReplace: Partial<Record<ThreatKey, string>> = {
    vip_notes: "notes",
    vip_favorite: "favorite",
    vip_intels: "feeds",
    vip_tags: "tags",
    vip_monitored: "monitored",
    vip_risk_level: "risk_level",
    vip_history: "history",
    vip_status: "status",
  };

  static prepareText(text: ThreatKey) {
    return Suggestion.thingsToReplace[text] ?? text;
  }

  // todo: move into FieldModel or something
  static resolveFieldName(fieldFromContext?: string): ThreatKey | undefined {
    if (!fieldFromContext) {
      return undefined;
    }

    // if field was processed by prepareText, it will be without prefixes, we have to add them back
    return (Object.entries(Suggestion.thingsToReplace).find(([_key, value]) => value === fieldFromContext)?.[0] ??
      fieldFromContext) as ThreatKey;
  }

  onMouseDown = (e: MouseEvent | KeyboardEvent, context: QLContext) => {
    // if this one clicked, our input will be blurred and menu gonna be destroyed
    e.preventDefault();
    e.stopPropagation();

    if (this.isDisabled) {
      return false;
    }

    this.onSelect(context);
    return false;
  };

  // Function called to select the suggestion. This modifies the current search string,
  // removes the selected token, and replaces it with the new suggestion,
  // adjusting for prefix and suffix snippets.
  onSelect = (context: QLContext) => {
    if (this.isDisabled) {
      return;
    }

    const { currentFullToken, cursorPos } = context;

    // current value of the search widget.
    let textValue = context.currentValue;

    // start position of the token in the search string.
    const startPos = this.isReplacement ? 0 : cursorPos - context.prefix.length;

    // If a token is currently selected, it is removed from the search string.
    if (currentFullToken?.end) {
      const tokenEndPos = currentFullToken.end;
      textValue = textValue.slice(0, startPos) + textValue.slice(tokenEndPos);
    }

    // Splits the search string into the parts before and after the token.
    const textBefore = textValue.slice(0, startPos);
    let textAfter = textValue.slice(startPos);

    // Trims any extra whitespace from the 'after' part of the string.
    textAfter = textAfter.trim();

    // Extracts any prefix and suffix snippets from the suggestion.
    let { snippetBefore, snippetAfter } = this;

    // If the suffix snippet contains multiple parts, separated by '|', it joins them together.
    // If the suggestion has no text and no prefix, it splits the suffix into prefix and suffix parts.
    const snippetAfterParts = snippetAfter.split("|");
    if (snippetAfterParts.length > 1) {
      snippetAfter = snippetAfterParts.join("");
      if (!snippetBefore && !this.text) {
        [snippetBefore, snippetAfter] = snippetAfterParts;
      }
    }

    // If the text before the token already ends with the prefix snippet, it removes the prefix snippet.
    if (textBefore.endsWith(snippetBefore)) {
      snippetBefore = "";
    }

    // If the text after the token already starts with the suffix snippet, it removes the suffix snippet.
    if (textAfter.startsWith(snippetAfter)) {
      snippetAfter = "";
    }

    const valueToUse = this.value || this.text;

    // Constructs the new token to be inserted into the search string, including any snippets.
    const textToPaste = snippetBefore + valueToUse + snippetAfter;

    // Calculates the new cursor position: the end of the newly inserted token.
    let cursorPosAfter = textBefore.length + textToPaste.length;

    // Adjusts the cursor position based on the contents of the suffix snippet.
    if (snippetAfterParts.length > 1) {
      cursorPosAfter -= snippetAfterParts[1].length;
    } else if (snippetAfterParts.length === 1 && snippetAfterParts[0].endsWith('"')) {
      // If the suffix snippet ends with a quote, it moves the cursor outside the quotes.
      cursorPosAfter += 1;
    }

    // Updates the search widget state with the new value and cursor position, and indicates the search query is not yet valid for saving.
    context.boundStateUpdater(
      {
        value: textBefore + textToPaste + textAfter,
        cursorPosition: cursorPosAfter,
      },
      false,
      false,
    );
  };

  highlight(fullText: string, highlightText: string, tooltipText: string) {
    if (!highlightText || !fullText) {
      return fullText;
    }

    if (highlightText === ">" || highlightText === "<") {
      // avoiding replacing > and <, since it will break HTML
      return fullText;
    }

    return fullText.replace(
      new RegExp(`(${escapeRegExp(highlightText)})`, "ig"),
      `<span class="text-blue-800 font-semibold" data-tooltip="${tooltipText}">$1</span>`,
    );
  }

  // Renders the suggestion into an HTML list item, adding necessary event listeners
  // and using the highlight function to highlight the prefix.
  render(id: number, context: QLContext) {
    const renderedSuggestion = this.createInstance(id.toString(), context);

    if (!renderedSuggestion) {
      return;
    }

    this.instance = renderedSuggestion;
    return renderedSuggestion;
  }

  createInstance(id: string, context: QLContext): HTMLElement | null {
    const renderedSuggestion = document.createElement("li");
    if (this.className) {
      // The token provided in this.className may contain HTML space characters, which are not valid in tokens, we need to replace them with a regular space.
      renderedSuggestion.classList.add(...this.className.replace(/&nbsp;/g, " ").split(" "));

      if (this.isDisabled) {
        renderedSuggestion.classList.add("disabled");
      }
    }

    renderedSuggestion.addEventListener("mousedown", (e) => this.boundOnMouseDown(e, context), {
      capture: true,
      passive: false,
      once: true,
    });

    renderedSuggestion.setAttribute("id", `suggestion-${id}`);

    const fieldReferences = snapshot(fieldReferencesState);
    const fieldId = this.text as ThreatKey;
    let tooltipText = "";
    if (this instanceof Suggestion) {
      const fieldReference = fieldReferences[fieldId] ?? fieldReferences[`vip_${fieldId}` as ThreatKey];
      if (fieldReference) {
        // todo: make this more robust, we have vip_ prefix for nucleus fields which are transformed and cut in widget
        if (fieldReference.description) {
          tooltipText = fieldReference.description;
        }

        renderedSuggestion.setAttribute("data-tooltip", tooltipText);
      }
    }

    if (!this.isReplacement && this.type !== "date") {
      // technically its a coincidence that this.isReplacement is also matches our needs for highlighting. Eventually it might be worth to refactor this
      renderedSuggestion.innerHTML = this.highlight(this.suggestionText, context.prefix, tooltipText);
    } else {
      renderedSuggestion.innerHTML = this.suggestionText;
    }

    return renderedSuggestion;
  }

  destroy() {
    // do nothing
    if (this.instance) {
      this.instance.remove();
    }
  }

  get isLogical() {
    const logicals = ["or", "and", "order by"];
    return logicals.includes(this.text.toLowerCase());
  }
}
