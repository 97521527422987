import classNames from "classnames";

const labels: Record<string, string> = {
  cisa: "CISA",
  epss: "EPSS",
  nvd: "NVD",
  greynoise: "GreyNoise",
  intel471: "Intel 471",
  mandiant: "Mandiant",
  defender: "Microsoft Defender",
  rf: "Recorded Future",
  zdi: "ZDI",
  vip: "Nucleus VIP",
  securin: "Securin",
  nucleus: "Nucleus VIP",
};

const prefixes_with_webp = ["nuclei", "v_intel", "vip", "nucleus"];
const prefixes_with_png = ["mandiant", "shodan", "metasploit", "shadowserver", "project"];

const prefixes_with_svg = [
  "cisa",
  "defender",
  "epss",
  "intel471",
  "greynoise",
  "rf",
  "nvd",
  "zdi",
  "exploitdb",
  "securin",
];

const imagesCustomSizes: Record<string, string> = {
  mandiant: "w-4 h-4",
  nuclei: "w-4 h-4",
  nvd: "w-7 h-3",
  epss: "w-[36px] h-[10px]",
};

const FeedIcon = ({
  fieldPrefix,
  isActive = true,
}: {
  fieldPrefix: string;
  isActive?: boolean;
}) => {
  const hasIcon =
    prefixes_with_webp.includes(fieldPrefix) ||
    prefixes_with_svg.includes(fieldPrefix) ||
    prefixes_with_png.includes(fieldPrefix);

  if (!hasIcon) {
    return null;
  }

  const ext = prefixes_with_svg.includes(fieldPrefix)
    ? "svg"
    : prefixes_with_png.includes(fieldPrefix)
      ? "png"
      : "webp";
  return (
    <picture className="flex items-center justify-center mr-1">
      <img
        alt={labels[fieldPrefix]}
        className={classNames("max-w-none", {
          ...(imagesCustomSizes[fieldPrefix]
            ? {
                [imagesCustomSizes[fieldPrefix]]: imagesCustomSizes[fieldPrefix],
              }
            : { "w-4 h-4": true }),
          // grayed out if not active
          grayscale: !isActive,
          "opacity-40": !isActive,
        })}
        src={`/static/img/feed_icons/${fieldPrefix}.${ext}`}
      />
    </picture>
  );
};

export default FeedIcon;
