import React from "react";

import Link from "@components/Link";
import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const CveIdRenderer: (context: CellContext<FrontendSerializedThreat, FrontendSerializedThreat>) => JSX.Element = (
  context,
) => {
  const threat = context.getValue();
  const { vip_id } = threat;

  if (!vip_id) {
    return <span className="text-gray-400">-</span>;
  }

  return (
    <span className="inline-flex items-center align-middle">
      <Link href={`/v/${vip_id}`} className="ease-nav-brand text-slate-600 hover:text-blue-700 font-semibold">
        {vip_id}
      </Link>
      <Link
        href={`/v/${vip_id}`}
        className="ease-nav-brand text-slate-600 hover:text-blue-700 ml-1 h-7"
        target="_blank"
      >
        <i className="fas fa-external-link-alt text-[14px]" />
      </Link>
    </span>
  );
};

export { CveIdRenderer };
