import classNames from "classnames";
import React from "react";
import TextTransition, { presets } from "react-text-transition";

import useQuickStart, { useQuickStartControls } from "@features/quickStart";

import RadialProgress from "../RadialProgress";

function QuickStart() {
  const quickStart = useQuickStart();
  const { openClose } = useQuickStartControls();

  if (!quickStart.enabled) {
    return null;
  }

  const handleOpen = (e) => {
    e.preventDefault();
    openClose(true);
  };

  return (
    <button
      className={classNames(
        "border-1 hover:bg-blue-100 flex flex-row items-center justify-center px-2 py-1 mx-3 text-gray-500 border border-gray-300 rounded cursor-pointer active:bg-blue-300",
        {
          "hover:text-blue-800 hover:border-blue-800": !quickStart.isCompleted,
          "hover:text-green-400 hover:border-green-700": quickStart.isCompleted,
        },
      )}
      onClick={handleOpen}
    >
      <RadialProgress percent={quickStart.percent} color={quickStart.isCompleted ? "text-green-400" : "text-nucleus"}>
        <span
          className={classNames({
            "text-green-400": quickStart.isCompleted,
            "text-nucleus": !quickStart.isCompleted,
          })}
        >
          <TextTransition springConfig={presets.wobbly}>{quickStart.progressText}</TextTransition>
        </span>
      </RadialProgress>
      <span className="sm:block hidden ml-2 text-xs font-bold text-gray-700">Quick Start</span>
    </button>
  );
}

export default QuickStart;
