import classNames from "classnames";
import { DateTime } from "luxon";
import type React from "react";
import { useRef } from "react";
import Flatpickr from "react-flatpickr";

import {
  type AlertsTableState,
  updateAlertsTableStateFilterValue,
  useAlertsTableFiltersValue,
  useAlertsTableFiltersValueFromAccessor,
} from "@features/alerts/state/alertsTable";
import { ResetButton } from "./ResetButton";

const CustomDivInput = ({
  inputRef,
  isDisabled,
  ...rest
}: { inputRef: any; isDisabled: boolean; [key: string]: any }) => {
  const { created_at } = useAlertsTableFiltersValue();
  const [startDate, endDate] = created_at;
  let displayValue = "";
  if (startDate && endDate) {
    displayValue = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} to ${DateTime.fromJSDate(endDate).toFormat(
      "yyyy-MM-dd",
    )}`;
  } else if (startDate) {
    displayValue = DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd");
  }

  return (
    <div
      className={classNames(rest.className, {
        "text-[10px]": displayValue,
        "text-gray-400": isDisabled,
        "leading-7": true,
      })}
      id={rest.id}
    >
      {displayValue || "Any date"}
      {/* Hiding the input since it was quite unreliable */}
      <input
        type="hidden"
        ref={inputRef}
        // value={displayValue}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="absolute text-white bg-white opacity-0 focus:outline-none"
        disabled={isDisabled}
      />
    </div>
  );
};

function DateRangeFilter({
  accessorKey,
  header,
  options,
}: {
  accessorKey: keyof Pick<AlertsTableState["filters"], "created_at">;
  header: string;
  options: { min_date?: string; max_date?: string; is_enabled: boolean };
}) {
  const flatpickrRef = useRef<any>();
  const { filterValue } = useAlertsTableFiltersValueFromAccessor(accessorKey);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!options.is_enabled) {
      return;
    }

    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.toggle();
    }

    e.stopPropagation();
  };

  return (
    <div>
      <label htmlFor={accessorKey} className="block mb-1">
        {header.split("_").join(" ")}
      </label>
      <div
        className={classNames("relative", {
          "cursor-pointer": options.is_enabled,
          "cursor-not-allowed": !options.is_enabled,
        })}
        onClick={handleClick}
      >
        <i className="absolute transform -translate-y-1/2 fas fa-calendar-alt left-2 top-1/2" />
        <Flatpickr
          ref={flatpickrRef}
          options={{
            mode: "range",
            minDate: options.min_date,
            maxDate: options.max_date,
          }}
          className="w-full py-1.5 pl-6 pr-2 border rounded-sm min-h-[40px] bg-white overflow-hidden normal-case"
          placeholder={header}
          onClose={(selectedDates: any) => {
            updateAlertsTableStateFilterValue(accessorKey, selectedDates);
          }}
          render={(props: any, ref: any) => {
            return <CustomDivInput inputRef={ref} {...props} isDisabled={!options.is_enabled} />;
          }}
        />

        {filterValue?.length ? <ResetButton filter={accessorKey} defaultFilterValue={[]} /> : null}
      </div>
    </div>
  );
}

export default DateRangeFilter;
