import classNames from "classnames";
import React, { type ComponentPropsWithoutRef } from "react";

import { MenuItem, Typography } from "@material-tailwind/react";

export const NavListMenuItem = React.forwardRef<
  HTMLAnchorElement,
  ComponentPropsWithoutRef<"a"> & {
    icon?: React.ElementType | null;
    url?: string;
    isActive: boolean;
    onLinkClick?: (e: React.MouseEvent<HTMLElement>) => void;
    itemClassName?: string;
    wrapperClassName?: string;
  }
>(({ icon, url, isActive, onLinkClick, children, itemClassName, wrapperClassName }, ref) => {
  const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
    if (onLinkClick && typeof onLinkClick === "function") {
      return onLinkClick(e);
    }

    return true;
  };

  const textClasses = classNames(
    "group-hover:text-gray-300 hover:text-gray-300 group-active:text-white active:text-white focus:text-blue-800 group-focus:text-blue-800 focus:bg-blue-700 active:bg-blue-700 rounded",
    "text-xs font-bold leading-4 tracking-wider",
    {
      "text-blue-500": isActive,
    },
  );

  return (
    <Typography
      as={url ? "a" : "div"} // if thats a category, we dont want to make it clickable
      ref={ref}
      href={url}
      variant="small"
      color="gray"
      onClick={clickHandler}
      className={classNames(
        "duration-400 flex items-center justify-center h-full group",
        wrapperClassName ?? "",
        textClasses,
      )}
    >
      <MenuItem
        onClick={clickHandler}
        className={classNames(
          "flex items-center gap-2 hover:bg-blue-500 rounded transition-colors duration-300",
          itemClassName ?? "",
          textClasses,
        )}
      >
        {icon ? React.createElement(icon, { className: "h-[18px] w-[18px]" }) : null} {children}
      </MenuItem>
    </Typography>
  );
});
