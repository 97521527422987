import type { ThreatKey } from "@interfaces/SerializedThreat";
import { proxy } from "valtio/vanilla";

type FieldReferenceState = {
  // comes from model introspections
  id: ThreatKey;
  type?: string;
  nullable?: boolean;
  name?: string;
  autocomplete?: boolean;
  searchable?: boolean;
  priority?: number;

  // comes from field references
  description?: string;
  // combined from model introspections and field references
  possible_values?: string[];
};

type FieldReferencesState = Partial<{
  [key in ThreatKey]: FieldReferenceState;
}>;

const fieldReferencesState = proxy<FieldReferencesState>({});

function updateFieldReferencesState(_fieldReferences: FieldReferencesState) {
  for (const [key, value] of Object.entries(_fieldReferences)) {
    if (key && value) {
      fieldReferencesState[key as ThreatKey] = value;
    }
  }
}

export { fieldReferencesState, updateFieldReferencesState };
export type { FieldReferenceState, FieldReferencesState };
