import { useSnapshot } from "valtio";

import alertsTableState from "./state/alertsTable";

function useSelectedAlerts() {
  const alertsTableStateSnap = useSnapshot(alertsTableState.selectedAlerts);

  return alertsTableStateSnap;
}

export default useSelectedAlerts;
