import { Suggestion } from "@components/DjangoQL/Suggestion";
import { FIELD_TYPE } from "@components/DjangoQL/utils";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import type { IntrospectionsResponse } from "@queries/useIntrospections";
import { LEXER_TOKENS, type LexerToken } from "@utils/Lexer";
import { getFieldConfig } from "@utils/getFieldConfig";

/**
 * Performs transformation of tokens into a proper query for backend. Thats solving our frontend syntax sugar for backend. Responsible for transforming the tokenized search query into a format that can be sent to the backend API. It iterates over each token and applies specific transformations based on the token type and field configuration. The transformations include converting boolean values, handling relative dates, converting percentages, and modifying field names for specific query patterns.
 */
function transformTokens(introspections: IntrospectionsResponse, tokens: LexerToken[]) {
  // tokens is a parsed search query, which will let us to build a proper query for backend using multiple patters for the same values
  let lastFieldName: ThreatKey | null = null; // last field name, which was used in search query
  let lastToken: LexerToken | null = null; // last token, which was used in search query

  const outputTokenValues = tokens.map((token) => token.value);

  for (let index = 0; index < tokens.length; index++) {
    const token = tokens[index];
    let tranformedValue = token.value;
    let prefix = index > 0 ? " " : "";
    let suffix = "";

    switch (token.name) {
      case "NAME": {
        lastFieldName = token.value as ThreatKey;

        // In case of custom fields, we may need to transform field name also.
        const resolvedField = Suggestion.resolveFieldName(lastFieldName);
        if (resolvedField && resolvedField !== lastFieldName) {
          lastFieldName = resolvedField;
          tranformedValue = resolvedField;
        }

        break;
      }

      case "NONE":
      case "TRUE":
      case "FALSE": {
        // we need to convert these values to proper format for backend if needed, since we have no boolean fields in database, they are 'tinyint(1)'
        const fieldConfig = getFieldConfig(introspections, lastFieldName);
        if (fieldConfig?.type === FIELD_TYPE.BOOLEAN) {
          // we have current field index and its value shall be converted. We can now work with the value in searchQuery, replacing its value using 'start' and 'end' positions
          if (["True", "False"].includes(token.value)) {
            tranformedValue = token.value === "True" ? "1" : "0";
          }
        }
        break;
      }

      case "PAREN_L": {
        prefix = " ";
        break;
      }

      case "PAREN_R": {
        prefix = "";
        break;
      }

      case "STRING_VALUE": {
        // This is getting too big and complex, we need to refactor it into smaller functions, maybe bind to FieldModels
        prefix = ' "';
        suffix = '"';

        // could be that this token may need to be transformed into int or float
        const fieldConfig = getFieldConfig(introspections, lastFieldName);
        if (!fieldConfig) {
          break;
        }

        if (fieldConfig.type === FIELD_TYPE.INTEGER) {
          tranformedValue = Number.parseInt(token.value, 10).toString();
          prefix = index > 0 ? " " : "";
          suffix = "";
        } else if (fieldConfig.type === FIELD_TYPE.FLOAT) {
          // transform to float with 1 decimal place
          const floatValue = Number.parseFloat(token.value);
          if (Number.isNaN(floatValue)) {
            // value is wrong, raise an error handler
            throw new Error(`Wrong value for field ${lastFieldName}: ${token.value}`);
          }

          tranformedValue = floatValue.toFixed(2);
          prefix = index > 0 ? " " : "";
          suffix = "";

          // Could be that this value is a percentage, so we need to convert it to float
          if (fieldConfig.constrains?.display_type === "percents" && floatValue > 1) {
            // database values for percents are floats between 0 and 1 and frontend values are integers between 0 and 100, we need to convert it
            tranformedValue = (floatValue / 100).toFixed(2);
          }
        } else if (fieldConfig.type === FIELD_TYPE.BOOLEAN) {
          // If boolean by config field is represented with string values, this might be frontend aliases which has to be converted into boolean values
          // Sincle booleans represents 0 and 1 in database, we expect that fields introspections options can be matched by position.
          const options = fieldConfig.constrains?.options || [];
          const optionIndex = options.findIndex((option) => option.toLowerCase() === token.value.toLowerCase());
          if (optionIndex !== -1) {
            tranformedValue = optionIndex.toString();
          }
        }

        break;
      }

      case "FLOAT_VALUE": {
        const fieldConfig = getFieldConfig(introspections, lastFieldName);
        if (!fieldConfig) {
          break;
        }

        // Float may be represented with '.X' instead of '0.X', so we need to add '0' in front of it
        if (token.value.startsWith(".")) {
          tranformedValue = `0${token.value}`;
        }

        const floatValue = Number.parseFloat(tranformedValue);
        // Could be that this value is a percentage, so we need to convert it to float
        if (fieldConfig.constrains?.display_type === "percents" && floatValue > 1) {
          // database values for percents are floats between 0 and 1 and frontend values are integers between 0 and 100, we need to convert it
          tranformedValue = (floatValue / 100).toFixed(2);
        }

        break;
      }

      case "INT_VALUE": {
        const fieldConfig = getFieldConfig(introspections, lastFieldName);
        if (!fieldConfig) {
          break;
        }

        const intValue = Number.parseInt(token.value, 10);

        // Could be that this value is a percentage, so we need to convert it to float
        if (fieldConfig.constrains?.display_type === "percents" && intValue > 1) {
          // database values for percents are floats between 0 and 1 and frontend values are integers between 0 and 100, we need to convert it
          tranformedValue = (intValue / 100).toFixed(2);
        }

        break;
      }

      case LEXER_TOKENS.IS: {
        // we need to convert 'is' to '=' for backend
        tranformedValue = "=";
        break;
      }

      case LEXER_TOKENS.IS_NOT: {
        // we need to convert 'is not' to '!=' for backend
        tranformedValue = "!=";
        break;
      }

      case LEXER_TOKENS.EMPTY: {
        // we need to convert 'empty' to None for backend
        tranformedValue = "None";
        break;
      }

      case LEXER_TOKENS.MORE_THAN: {
        tranformedValue = "<=";
        break;
      }

      case LEXER_TOKENS.WITHIN: {
        tranformedValue = ">=";
        break;
      }

      case LEXER_TOKENS.WITHIN_NEXT: {
        tranformedValue = "<=";
        break;
      }

      case LEXER_TOKENS.AUTHOR_IS:
      case LEXER_TOKENS.AUTHOR_IS_NOT: {
        // query example: 'notes author is "max@moonlightlabs.com"'
        // in this scenario, we convert tokens to represend correct query for backend: 'vip_notes__author__user__email = "max@moonlightlabs.com"
        // notes tokens was alrady transformed to 'vip_notes' in name transformation, so we need to add '__author__user__email' to it and replace 'author is' with equality token
        if (token.value === "author is") {
          tranformedValue = "=";
        } else if (token.value === "author is not") {
          tranformedValue = "!=";
        }

        // we need to modify previous token to add '__author__user__email' to it, however its already processed.
        outputTokenValues[index - 1] += "__author__user__email";
        break;
      }

      case LEXER_TOKENS.ORGANIZATION_IS:
      case LEXER_TOKENS.ORGANIZATION_IS_NOT: {
        // query example: 'notes organization is "Moonlight Labs"'
        // in this scenario, we convert tokens to represend correct query for backend: 'vip_notes__organization__name = "Moonlight Labs"
        // notes tokens was alrady transformed to 'vip_notes' in name transformation, so we need to add '__organization__name' to it and replace 'organization is' with equality token
        if (token.value === "organization is") {
          tranformedValue = "=";
        } else if (token.value === "organization is not") {
          tranformedValue = "!=";
        }

        // we need to modify previous token to add '__organization__name' to it, however its already processed.
        outputTokenValues[index - 1] += "__author__organizations__name";
        break;
      }

      case LEXER_TOKENS.UPDATED_AT:
      case LEXER_TOKENS.CREATED_AT: {
        // CREATED_AT contains multiple cases
        if (token.value === "created after" || token.value === "updated after") {
          tranformedValue = ">";
        } else if (token.value === "created before" || token.value === "updated before") {
          tranformedValue = "<";
        } else if (token.value === "created within" || token.value === "updated within") {
          tranformedValue = ">=";
        } else if (token.value === "created more than" || token.value === "updated more than") {
          tranformedValue = "<=";
        }

        if (token.name === LEXER_TOKENS.UPDATED_AT) {
          outputTokenValues[index - 1] += "__date_updated";
        } else {
          outputTokenValues[index - 1] += "__date_created";
        }

        break;
      }

      case LEXER_TOKENS.PRIVATE:
      case LEXER_TOKENS.PUBLIC: {
        // notes is private/public query
        // final query should be 'vip_notes__visibility = "public"' or 'vip_notes__visibility = "private"'
        // we need to modify name token to add '__visibility' to it, however its already processed.
        outputTokenValues[index - 2] += "__visibility";

        // we need to convert 'private' to 'private' for backend into string
        tranformedValue = `"${token.value === "public" ? "public" : "private"}"`;
        break;
      }

      case LEXER_TOKENS.HISTORY: {
        tranformedValue = `vip_history(${token.value})`;
        break;
      }
    }

    // no need spaces after brackets [todo verify this]
    if (lastToken && ["PAREN_R", "PAREN_L"].includes(lastToken.name)) {
      prefix = prefix.trim();
    }

    // Modify token in-place in the outputTokens array
    outputTokenValues[index] = `${prefix}${tranformedValue}${suffix}`;

    lastToken = {
      ...token,
      value: tranformedValue,
    };
  }

  return outputTokenValues.join("");
}

export default transformTokens;
