import type React from "react";
import { createContext, useContext, useRef } from "react";
import { proxy, useSnapshot } from "valtio";
import { NucleusQLState } from "./NucleusQLState";

const NucleusQLContext = createContext<NucleusQLState>(new NucleusQLState());

const NucleusQLProvider = ({
  children,
  initialValue,
}: {
  children: React.ReactNode;
  initialValue?: string;
}) => {
  const localWidgetState = useRef(proxy<NucleusQLState>(new NucleusQLState(initialValue))).current;

  return <NucleusQLContext.Provider value={localWidgetState}>{children}</NucleusQLContext.Provider>;
};

function useClosestNucleusQLState() {
  const nucleusQLState = useContext(NucleusQLContext);
  const nucleusQLStateSnap = useSnapshot<NucleusQLState>(nucleusQLState);

  return [nucleusQLState, nucleusQLStateSnap] as const;
}

export { NucleusQLContext, NucleusQLProvider, useClosestNucleusQLState };
