import React, { useMemo } from "react";

import { useOrganizationCveStatuses } from "@features/cve_statuses";
import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import { Tooltip } from "@material-tailwind/react";
import type { CellContext } from "@tanstack/react-table";

const Renderer = ({ value }: { value: string }) => {
  const statuses = useOrganizationCveStatuses();

  const currentStatus = useMemo(
    () => statuses?.find((status) => status && status.id === value.toString()),
    [value, statuses],
  );

  const displayValue = useMemo(() => (currentStatus ? `${currentStatus.name}` : "Unassigned"), [currentStatus]);

  const cveStatusDescription = currentStatus?.description;

  return (
    <span
      className={`inline-flex items-center justify-center rounded-full font-semibold min-w-[80px] text-xs px-3 py-1 ${!value ? " !text-gray-500 !justify-start" : ""}`}
    >
      {displayValue}
      {cveStatusDescription ? (
        <Tooltip content={<div className="w-80">{cveStatusDescription}</div>}>
          <i className="ml-1 text-xs cursor-pointer fas fa-info-circle" />
        </Tooltip>
      ) : null}
    </span>
  );
};

const MemoizedRenderer = React.memo(Renderer);

const VipStatusRenderer: React.FunctionComponent<CellContext<FrontendSerializedThreat, string>> = (context) => {
  const value = context.getValue();

  if (!value) {
    return <span className="text-gray-500">-</span>;
  }

  return <MemoizedRenderer value={value} />;
};

export default VipStatusRenderer;
