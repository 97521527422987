import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";

import Link from "@components/Link";
import withConfirmation from "@components/withConfirmation";
import { useSelectedAlerts } from "@features/alerts";
import type SerializedAlert from "@interfaces/SerializedAlert";
import { Checkbox } from "@material-tailwind/react";
import {
  type CellContext,
  type ColumnDef,
  type OnChangeFn,
  type PaginationState,
  type Table,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import alertsTableState, {
  addIntoSelection,
  removeFromSelection,
  toggleAlertDetails,
  updateAlertsTableState,
} from "../state/alertsTable";
import { type AlertsApiResponse, useDeleteAlerts } from "./useAlerts";
import { useAlertsColumns } from "./useAlertsColumns";

const DeleteAlertItemRef = React.forwardRef<HTMLAnchorElement>((props, ref) => {
  return (
    <Link color="danger" className="text-xs" {...props} ref={ref}>
      Delete
    </Link>
  );
});

export type AlertsTableColumnDef = ColumnDef<SerializedAlert> & {
  HeaderFilter?: ({
    options,
    table,
  }: {
    options: {
      label: string;
      value: string;
    }[];
    allOptions: {
      label: string;
      value: string;
    }[];
    table: Table<SerializedAlert>;
  }) => React.ReactNode;
  options?: {
    label: string;
    value: string;
  }[];
  allOptions?: {
    label: string;
    value: string;
  }[];
};

export function ActionsCell(props: CellContext<SerializedAlert, string>) {
  const id = props.row.original.id;
  const { mutateAsync } = useDeleteAlerts();
  const onDeleteClick = () => {
    mutateAsync([id]);
  };

  const DeleteLinkWithConfirmation = withConfirmation(
    DeleteAlertItemRef,
    {
      title: "Delete Alert",
      description: "Are you sure you want to delete selected alert?",
    },
    onDeleteClick,
  );

  const handleViewChanges = () => {
    toggleAlertDetails(id);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-1 px-3 lg:flex-row">
      <div className="lg:border-r-2 lg:border-gray-200 lg:py-1 lg:px-2">
        <Link className="block text-xs" onClick={handleViewChanges}>
          View changes
        </Link>
      </div>
      <div className="lg:py-1 lg:px-2">
        <DeleteLinkWithConfirmation />
      </div>
    </div>
  );
}

export const CheckBoxCell = (props: CellContext<SerializedAlert, string>) => {
  const value = props.row.original.id;
  const selectedAlerts = useSelectedAlerts();
  return (
    <Checkbox
      checked={selectedAlerts.has(value)}
      value={value}
      color="blue"
      id={`alert-${value}`}
      onChange={(e) => {
        if (e.target.checked) {
          addIntoSelection(Number.parseInt(e.target.value));
        } else {
          removeFromSelection(Number.parseInt(e.target.value));
        }
      }}
    />
  );
};

export const CheckBoxAllCell = (props: { table: Table<SerializedAlert> }) => {
  const { table } = props;
  const selectedAlerts = useSelectedAlerts();
  return (
    // Not sure why I am not able to center the checkbox within flex, but this is a workaround
    <div className="mt-4">
      <Checkbox
        checked={selectedAlerts.size > 0}
        color="blue"
        id={"alert-selectAll"}
        onChange={(e) => {
          const rows = table.getCoreRowModel().rows;
          if (e.target.checked) {
            for (const row of rows) {
              addIntoSelection(row.original.id);
            }
          } else {
            for (const row of rows) {
              removeFromSelection(row.original.id);
            }
          }
        }}
      />
    </div>
  );
};

function useAlertsTable(alertsData?: AlertsApiResponse) {
  const alertsTableStateSnap = useSnapshot(alertsTableState);
  const columns = useAlertsColumns(alertsData?.filter_boundaries);
  const [alerts, setAlerts] = useState<AlertsApiResponse["alerts"]>(
    alertsData?.alerts || { count: 0, next: null, previous: null, results: [] },
  );

  useEffect(() => {
    setAlerts(alertsData?.alerts || { count: 0, next: null, previous: null, results: [] });
  }, [alertsData]);

  // sync the table state with the filters
  const handlePaginationChange: OnChangeFn<PaginationState> = (updater: (old: PaginationState) => PaginationState) => {
    const next = updater({
      pageIndex: alertsTableStateSnap.page,
      pageSize: alertsTableStateSnap.perPage,
    });

    updateAlertsTableState({
      page: next.pageIndex,
      perPage: next.pageSize,
    });

    return next;
  };

  // Initialize table instance
  const table = useReactTable<SerializedAlert>({
    columns,
    data: alerts.results,
    getCoreRowModel: getCoreRowModel(),

    // Pagination
    manualPagination: true,
    pageCount: Math.ceil(alerts.count / alertsTableStateSnap.perPage),
    onPaginationChange: handlePaginationChange,
    state: {
      columnSizing: {
        select: 40,
      },
      pagination: {
        pageIndex: alertsTableStateSnap.page,
        pageSize: alertsTableStateSnap.perPage,
      },
    },
  });

  return table;
}

export default useAlertsTable;
