import classNames from "classnames";
import { has, isNil } from "lodash-es";
import React from "react";

import { useUnseenAlertsCount } from "@features/alerts";
import { useClosestListingTableState } from "@features/listing";
import { EyeIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon, CircleStackIcon, CodeBracketSquareIcon, HomeIcon } from "@heroicons/react/24/outline";
import useFeeds from "@hooks/useFeeds";
import useLocation from "@hooks/useLocation";
import useOnClickOutside from "@hooks/useOnClickOutside";
import { Menu, MenuHandler, MenuList } from "@material-tailwind/react";
import useSiteSettings from "@queries/useSiteSettings";

import { useClosestNucleusQLState } from "@components/NucleusQL/NucleusQLProvider";
import { NavListMenuItem } from "./NavList/NavListMenuItem";

function NavList() {
  const { listingTableState } = useClosestListingTableState();
  const [nucleusQLState] = useClosestNucleusQLState();

  const currentPath = useLocation();
  const { data } = useSiteSettings();
  const { is_watch_searches_enabled: isWatchSearchesEnabled, is_malware_listing_enabled: isMalwareListingEnabled } =
    data ?? {};
  const unseenAlerts = useUnseenAlertsCount();
  const { feeds } = useFeeds();

  let analyzeSubItems = undefined;

  if (isMalwareListingEnabled && feeds?.some((feed) => feed.db_prefix === "mandiant" && feed.is_enabled)) {
    analyzeSubItems = [
      {
        label: "Vulnerabilities",
        url: "/analyze/",
        hasBadge: false,
      },
    ];

    analyzeSubItems.push({
      label: "Malware [Beta]",
      url: "/malware/",
      hasBadge: false,
    });
  }

  const navListItems = [
    {
      label: "",
      mobileLabel: "Home",
      icon: HomeIcon,
      url: "/",
      onClickSideEffect: () => {
        listingTableState.updateSearchQuery("");
        nucleusQLState.updateState({
          value: "",
          queryParsingError: null,
        });
      },
    },
    {
      label: "Analyze",
      icon: null, // CircleStackIcon
      url: "/analyze/",
      subItems: analyzeSubItems,
    },
    {
      label: "Monitor",
      icon: null, // EyeIcon,
      isAvailable: isWatchSearchesEnabled,
      hasBadge: unseenAlerts > 0,
      subItems: [
        {
          label: "Configure",
          mobileLabel: "Configure Monitoring",
          url: "/monitoring/",
          hasBadge: false,
        },
        {
          label: "Alerts",
          mobileLabel: "Alerts",
          url: "/monitoring/alerts/",
          hasBadge: unseenAlerts > 0,
        },
      ],
    },
    {
      label: "Integrations",
      icon: null, // CodeBracketSquareIcon,
      url: "/integrations/",
    },
  ];

  const menuRef = React.useRef<HTMLButtonElement>(null);
  const [openedMenu, setOpenedMenu] = React.useState<string | null>(null);
  useOnClickOutside(menuRef, () => setOpenedMenu(null));

  return (
    <ul className="flex flex-col mt-2 mb-4 h-full lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-2">
      {navListItems.map(({ label, mobileLabel, icon, url, isAvailable, hasBadge, onClickSideEffect }, key) => {
        if (isAvailable === false) {
          return null;
        }

        let isActive = currentPath === url;

        if (navListItems[key].subItems && Array.isArray(navListItems[key].subItems)) {
          isActive = navListItems[key].subItems!.some(({ url }) => currentPath === url);
        }

        const mobileView = navListItems[key]?.subItems?.map(({ label: subLabel, url: subUrl }) => {
          const isActive = currentPath === subUrl;
          return (
            <NavListMenuItem
              key={`${label}-${subLabel}-m`}
              url={subUrl}
              icon={icon}
              isActive={isActive}
              itemClassName="group-active:text-blue-800 py-3 rounded-none transition-colors"
              wrapperClassName={classNames(
                "lg:hidden z-50",
                "items-center justify-center h-full transition-colors group p-0",
                {
                  "bg-blue-300 text-blue-600": isActive,
                },
              )}
            >
              {mobileLabel ?? subLabel}
            </NavListMenuItem>
          );
        });

        const hasMenu = !isNil(navListItems[key].subItems) && navListItems[key].subItems!.length > 0;

        const onLinkClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.stopPropagation();

          onClickSideEffect?.();

          if (hasMenu) {
            e.preventDefault();
            if (openedMenu === label) {
              setOpenedMenu(null);
            } else {
              setOpenedMenu(label);
            }
          }
        };

        const menuItem = (
          <NavListMenuItem
            key={label}
            icon={icon}
            url={url}
            isActive={isActive}
            wrapperClassName={classNames({
              // mobile-desktop behavior
              "hidden lg:flex": hasMenu,
              // desktop-only behavior
              "bg-blue-100 text-blue-600 rounded": isActive,
            })}
            onLinkClick={onLinkClickHandler}
          >
            {label ?? mobileLabel}
            {hasMenu ? (
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`h-3.5 w-3.5 transition-transform ${openedMenu === label ? "rotate-180" : ""}`}
              />
            ) : null}
            {hasBadge ? (
              <div className="grid absolute right-0 -top-2 rounded-xl">
                <i className="text-base text-orange-800 fa-solid fa-circle-exclamation" />
              </div>
            ) : null}
          </NavListMenuItem>
        );

        return (
          <div key={`Menu-${label}`} className="relative">
            {/* For desktop display */}
            {hasMenu ? (
              <Menu
                key={`Menu-${label}`}
                open={openedMenu === label}
                ref={menuRef}
                placement="bottom"
                offset={{
                  mainAxis: 0,
                  crossAxis: 0,
                  alignmentAxis: 0,
                }}
                animate={{
                  mount: { y: 0 },
                  unmount: { y: -25 },
                }}
              >
                <MenuHandler>{menuItem}</MenuHandler>

                <MenuList className="hidden overflow-visible gap-2 p-0 rounded lg:flex">
                  <ul className="flex flex-col col-span-4 gap-0 w-full rounded-none">
                    {navListItems[key].subItems?.map(({ label: subLabel, url: subUrl, hasBadge: subHasBadge }) => {
                      const isActive = currentPath === subUrl;
                      return (
                        <NavListMenuItem key={`${label}-${subLabel}-d`} url={subUrl} isActive={isActive}>
                          {subLabel}
                          {subHasBadge && (
                            <div className="inline-flex justify-center items-center w-6 h-6 text-xs text-white bg-orange-700 rounded-full border border-white">
                              {unseenAlerts}
                            </div>
                          )}
                        </NavListMenuItem>
                      );
                    })}
                  </ul>
                </MenuList>
              </Menu>
            ) : (
              menuItem
            )}
            {/* For mobile view */}
            {mobileView}
          </div>
        );
      })}
    </ul>
  );
}

export default NavList;
