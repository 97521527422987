import Badge from "@components/Badge";
import type { FrontendSerializedThreat, VulncheckNvcReference } from "@interfaces/SerializedThreat";
import { Tooltip } from "@material-tailwind/react";
import type { CellContext } from "@tanstack/react-table";

const LIMIT_REFERENCES_TO = 5;
const MAX_TAGS_TO_SHOW = 4;

const renderTags = (tags: string[]) => {
  if (!tags?.length) {
    return "";
  }

  const visibleTags = tags.slice(0, MAX_TAGS_TO_SHOW);
  const remainingTags = tags.slice(MAX_TAGS_TO_SHOW);

  return (
    <div className="mt-1 flex flex-wrap gap-1">
      {visibleTags.map((tag, index) => (
        <Badge key={`vulncheck_nvd_references_tag_${tag}`} color="info" size="small">
          {tag}
        </Badge>
      ))}

      {remainingTags.length > 0 && (
        <Tooltip
          content={
            <div className="text-sm">
              {remainingTags.map((tag, index) => (
                <Badge key={`vulncheck_nvd_references_tag_${tag}`} color="info" size="small" className="m-1">
                  {tag}
                </Badge>
              ))}
            </div>
          }
        >
          <Badge color="default" size="small">
            +{remainingTags.length} more
          </Badge>
        </Tooltip>
      )}
    </div>
  );
};

const renderVulncheckNvdReference = (reference: VulncheckNvcReference, index: number) => (
  <div key={`vulncheck_nvd_reference_${index}`} className="mb-2">
    <a
      className="flex font-semibold text-xs transition-all flex-start ease-nav-brand text-slate-600 hover:text-blue-700"
      href={reference.url}
      target="_blank"
      rel="noreferrer"
    >
      <span>
        {reference.source}
        <i className="inline ml-1 text-xs fas fa-external-link-alt" />
      </span>
    </a>
    {renderTags(reference.tags)}
  </div>
);

export const vulncheck_nvd_references: (
  context: CellContext<FrontendSerializedThreat, VulncheckNvcReference[]>,
) => JSX.Element = (context) => {
  const values = context.getValue();

  if (!Array.isArray(values) || values?.length === 0) {
    return <span className="text-sm text-gray-500">-</span>;
  }

  const fullView = <div>{values.map(renderVulncheckNvdReference)}</div>;

  if (values.length > LIMIT_REFERENCES_TO) {
    return (
      <div>
        <div>
          {values
            .slice(0, LIMIT_REFERENCES_TO)
            .map((reference, index) => renderVulncheckNvdReference(reference, index))}
        </div>
        <div className="mt-2 text-xs text-right text-gray-600 cursor-progress">
          And {values.length - LIMIT_REFERENCES_TO} more...
        </div>
      </div>
    );
  }

  return <div>{fullView}</div>;
};

export { renderVulncheckNvdReference };