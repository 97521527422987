import React from "react";

import { CircleStackIcon, DocumentIcon, HeartIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Menu, MenuHandler, MenuItem, MenuList, Typography } from "@material-tailwind/react";
import useSiteSettings from "@queries/useSiteSettings";

const HelpSection = () => {
  const { data: siteSettings } = useSiteSettings();
  const { is_field_reference_enabled: isFieldReferenceEnabled } = siteSettings ?? {};

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const closeMenu = () => setIsMenuOpen(false);

  // Documentation
  // Support & Feature Requests
  const menuItems = [
    {
      label: "Documentation",
      icon: DocumentIcon,
      url: siteSettings?.documentation_url,
    },
    {
      label: "Support & Feature Requests",
      icon: HeartIcon,
      url: siteSettings?.support_url,
    },
    {
      label: "Vulnerability Intelligence Sources",
      icon: CircleStackIcon,
      url: isFieldReferenceEnabled ? "/sources" : undefined,
      blank: false,
    },
  ].filter((item) => item.url);

  if (!menuItems.length) {
    return null;
  }

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <div className="flex justify-center items-center">
          <div className="flex flex-row justify-center items-center px-2 py-1 mx-3 text-blue-500 rounded border-2 border-blue-500 cursor-pointer hover:bg-blue-100 active:bg-blue-200 hover:text-blue-600 active:text-blue-700">
            <QuestionMarkCircleIcon className="w-5 h-5 font-semibold" strokeWidth={2} />
          </div>
        </div>
      </MenuHandler>
      <MenuList className="p-1">
        {menuItems.map(({ label, icon, url, blank }, key) => {
          return (
            <Typography
              key={label}
              as="a"
              href={url}
              variant="small"
              target={blank ? "_blank" : undefined}
              rel={blank ? "noreferrer" : undefined}
            >
              <MenuItem key={label} onClick={closeMenu} className="flex gap-2 items-center rounded">
                {React.createElement(icon, {
                  className: "h-4 w-4",
                  strokeWidth: 2,
                })}
                <Typography as="span" variant="small" className="font-normal" color={"blue"}>
                  {label}
                </Typography>
              </MenuItem>
            </Typography>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default HelpSection;
