import classNames from "classnames";
import React from "react";

import Link from "@components/Link";
import Tooltip from "@components/Tooltip";

import type { QuickStartItem } from "@features/quickStart";

const QuickStartItems = ({
  items,
  isCompletedList = false,
  handleMakeCompleted,
}: {
  items: QuickStartItem[];
  isCompletedList?: boolean;
  handleMakeCompleted?: (id: string) => void;
}) => {
  const container = React.useRef<HTMLDivElement | null>(null);
  return (
    <div className="flex flex-col" ref={container}>
      <div className="space-y-3">
        {items.map((item) => (
          <div
            className={classNames(
              "group flex flex-row items-center justify-start p-1 transition-colors duration-200 border rounded shadow",
              { "gap-2 px-3": isCompletedList },
            )}
            key={`${item.title}`}
          >
            <div>
              {isCompletedList ? (
                <i className="fas fa-check text-xl text-green-600" />
              ) : (
                <svg
                  className="sm:w-8 sm:h-8 w-6 h-6 text-blue-900 transition-colors duration-200"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              )}
            </div>

            <div
              className={classNames("flex items-start gap-1 p-2 w-full relative", {
                "flex-col": !isCompletedList,
                "flex-row": isCompletedList,
              })}
            >
              <span className="flex flex-row justify-between gap-1 text-sm font-semibold text-gray-800 align-baseline transition-colors duration-200">
                {item.title}
                {!isCompletedList && item.learnMoreLink ? (
                  <Link
                    className="hover:text-blue-400 text-xs text-blue-500 transition-colors duration-300"
                    href={item.learnMoreLink}
                    target="_blank"
                  >
                    {/* <i className="fas fa-info-circle"></i> */}
                    <i className="fas fa-question-circle" />
                  </Link>
                ) : null}
              </span>
              {isCompletedList ? (
                <div className="-top-1 absolute right-0">
                  <Tooltip value={item.description} container={container.current}>
                    {item.learnMoreLink ? (
                      <Link
                        className="hover:text-blue-700 underline-offset-1 ml-auto text-xs !text-blue-400 underline transition-colors duration-300"
                        href={item.learnMoreLink}
                        target="_blank"
                      >
                        <i className="fas fa-info-circle" />
                      </Link>
                    ) : (
                      <i className="fas fa-info-circle ml-auto text-xs text-blue-400" />
                    )}
                  </Tooltip>
                </div>
              ) : (
                <>
                  <span className="text-xs text-gray-500 transition-colors duration-200">{item.description}</span>
                </>
              )}
            </div>
            {isCompletedList ? null : (
              <div className="flex flex-col self-end px-1 py-2">
                <Tooltip value="Mark as completed" container={container.current}>
                  <button
                    className="hover:text-green-700 active:text-green-900 text-2xl text-gray-500 transition-colors duration-200"
                    onClick={() => {
                      handleMakeCompleted?.(item.id);
                    }}
                  >
                    <i className="fas fa-check-circle" />
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuickStartItems;
