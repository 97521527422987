import React from "react";

import FeedIcon from "@components/FeedIcon";
import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const IntelsRenderer: (context: CellContext<FrontendSerializedThreat, string[]>) => JSX.Element = (context) => {
  const value = context.getValue();

  if (!value || !Array.isArray(value) || value.length === 0) {
    return <div className="text-gray-400">None</div>;
  }

  return (
    <div className="gap-x-1 gap-y-2 grid items-center justify-center grid-cols-3">
      {value.map((feed) => (
        <FeedIcon fieldPrefix={feed} key={feed} />
      ))}
    </div>
  );
};

export default IntelsRenderer;
