declare global {
  // WaffleJS exposes a global waffle object that gives access to flags, switches, and samples.
  var waffle: any;
}

const ALL_FLAGS = {
  WEEKLY_ROLLUP_FLAG: "WEEKLY_ROLLUP_FLAG",
  AGGREGATED_FIELDS_FLAG: "AGGREGATED_FIELDS_FLAG",
  CVE_PDF_FLAG: "CVE_PDF_FLAG",
  VIP_HISTORY_FLAG: "VIP_HISTORY_FLAG",
  VULNERABILITY_STATUS_FLAG: "VULNERABILITY_STATUS_FLAG",
};

function is_flag_enabled(flag_name: keyof typeof ALL_FLAGS): boolean {
  if (typeof window !== "undefined" && "waffle" in window) {
    return window.waffle?.flag_is_active(flag_name) ?? false;
  }

  return false;
}

export { is_flag_enabled };
