import React from "react";

import {
  ChevronDownIcon,
  CodeBracketIcon,
  PowerIcon,
  Square3Stack3DIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Button, Menu, MenuHandler, MenuItem, MenuList, Typography } from "@material-tailwind/react";

const profileMenuItems = [
  {
    label: "Profile",
    icon: UserCircleIcon,
    url: "/profile/",
  },
  {
    label: "My organization",
    icon: Square3Stack3DIcon,
    url: "/organization/",
  },
  {
    label: "API",
    icon: CodeBracketIcon,
    url: "/swagger/",
  },
  {
    label: "Logout",
    icon: PowerIcon,
    url: "/logout/",
  },
];

function ProfileMenu({ is_impersonated }: { is_impersonated: boolean }) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          size="lg"
          color="blue"
          className="lg:ml-auto flex items-center gap-1 py-1 pl-3 pr-4 rounded"
          ripple={false}
        >
          <UserIcon className="w-8 h-8" strokeWidth={2} />
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""}`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-0">
        {profileMenuItems.map(({ label, icon, url }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <Typography key={label} as="a" href={url} variant="small" className="border-0 outline-none">
              <MenuItem
                key={label}
                onClick={closeMenu}
                className={`flex items-center gap-2 rounded ${
                  isLastItem
                    ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                    : "hover:bg-blue-500 focus:bg-gray-300 active:bg-gray-300 hover:text-gray-300 transition-colors"
                }`}
              >
                {React.createElement(icon, {
                  className: `h-5 w-5 ${isLastItem ? "text-red-500" : ""}`,
                  strokeWidth: 2,
                })}
                <Typography as="span" variant="small" className="font-semibold" color={isLastItem ? "red" : "inherit"}>
                  {label}
                </Typography>
              </MenuItem>
            </Typography>
          );
        })}
      </MenuList>
    </Menu>
  );
}

export default ProfileMenu;
