import { type UseSuspenseQueryOptions, useSuspenseQuery } from "@tanstack/react-query";

import type { SerializedRiskLevel } from "../interfaces";

export const organizationRiskLevelsUrlEndpoint = "/api/v1/organization/risk-levels/";

export const riskLevelsQueryKey = ["riskLevels"];

export const riskLevelsChangesQueryKey = ["riskLevelChangeLogs"];

const fetchRiskLevels = async () => {
  const response = await fetch(organizationRiskLevelsUrlEndpoint);
  if (!response.ok) {
    return [];
  }

  return response.json();
};

export function useRiskLevelsQuery(options: Partial<UseSuspenseQueryOptions<SerializedRiskLevel[]>> = {}) {
  return useSuspenseQuery<SerializedRiskLevel[]>({
    queryKey: riskLevelsQueryKey,
    queryFn: fetchRiskLevels,
    staleTime: 1 * 60 * 1000,
    ...options,
  });
}
