import ErrorUnauthorized from "@errors/ErrorUnauthorized";
import getCookie from "./getCookie";

async function fetchWithSession<T = any>(input: RequestInfo, init?: RequestInit) {
  const options = {
    headers: {
      // This is needed to make @xhr_request_only validation decorator happy with the request
      "X-Requested-With": "XMLHttpRequest",
      // fetchWithSession is supposed to be only used for AJAX requests
      "Content-Type": "application/json",
    } as Record<string, string>,
  };

  // For PUT/PATCH/POST requests we are also setting CSRF token
  if (init?.method && ["PUT", "PATCH", "POST"].includes(init.method)) {
    options.headers["X-CSRFToken"] = getCookie("csrftoken");
  }

  const response = await fetch(input, { ...init, ...options });

  if (!response.ok) {
    const data = await response.json().catch(() => ({}));

    if (response.status === 403) {
      // Only throw ErrorUnauthorized for 403 status codes
      if (data?.detail) {
        throw new ErrorUnauthorized(data.detail);
      }

      throw new ErrorUnauthorized("Access Forbidden");
    }

    // For other errors, throw a generic error
    throw new Error(
      `HTTP error! status: ${response.status}, message: ${
        data?.message || data?.detail || data?.error || response.statusText
      }`,
    );
  }

  return response.json() as Promise<T>;
}

export default fetchWithSession;
