declare const currentUserID: number; // declared in main template html file and passed from django

import type React from "react";
import { Suspense } from "react";

import ErrorUnauthorized from "@errors/ErrorUnauthorized";
import { ToastProvider } from "@hooks/useCreateToast";
import { ThemeProvider } from "@material-tailwind/react";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 2, // 2 minutes
    },
  },
  queryCache: new QueryCache({
    onError(error: Error) {
      if (error instanceof ErrorUnauthorized) {
        window.location.href = `/login/?next=${window.location.pathname}`;
      }
    },
  }),
});

const sessionStoragePersister = createSyncStoragePersister({
  storage: window.sessionStorage,
  key: `_session_${currentUserID}`,
});

type ReactNodeLike = React.ReactNode;
const App = ({ children }: { children: React.ReactNode }): NonNullable<ReactNodeLike> => {
  return (
    <div>
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: sessionStoragePersister }}>
        <ReactQueryDevtools initialIsOpen={false} position="left" />
        <Suspense fallback={<div />}>
          <ToastProvider>{children}</ToastProvider>
        </Suspense>
      </PersistQueryClientProvider>
    </div>
  );
};

// Wrappers which are used in all pages
const Providers = ({ children }: { children: React.ReactNode }) => {
  const nucleusTheme = {
    popover: {
      defaultProps: {
        placement: "top",
        offset: 5,
        dismiss: {},
        animate: {
          unmount: {},
          mount: {},
        },
        className: "",
      },
      styles: {
        base: {
          bg: "bg-white",
          p: "p-2",
          border: "border border-gray-400",
          borderRadius: "rounded",
          boxShadow: "shadow shadow-blue-gray-500/10",
          fontFamily: "font-sans",
          fontSize: "text-sm",
          fontWeight: "font-normal",
          color: "text-blue-gray-500",
          outline: "focus:outline-none",
          overflowWrap: "break-words",
          whiteSpace: "whitespace-normal",
        },
      },
    },
    radio: {
      defaultProps: {
        color: "blue",
        label: undefined,
        icon: undefined,
        ripple: true,
        className: "",
        disabled: false,
        containerProps: undefined,
        labelProps: undefined,
        iconProps: undefined,
      },
      valid: {
        colors: [
          "blue-gray",
          "gray",
          "brown",
          "deep-orange",
          "orange",
          "amber",
          "yellow",
          "lime",
          "light-green",
          "green",
          "teal",
          "cyan",
          "light-blue",
          "blue",
          "indigo",
          "deep-purple",
          "purple",
          "pink",
          "red",
        ],
      },
      styles: {
        base: {
          root: {
            display: "inline-flex",
            alignItems: "items-center",
          },
          container: {
            position: "relative",
            display: "flex",
            alignItems: "items-center",
            cursor: "cursor-pointer",
            p: "p-3",
            borderRadius: "rounded-full",
          },
          input: {
            peer: "peer",
            position: "relative",
            appearance: "appearance-none",
            width: "w-5",
            height: "h-5",
            borderWidth: "border",
            borderRadius: "rounded-full",
            borderColor: "border-blue-gray-200",
            cursor: "cursor-pointer",
            transition: "transition-all",
            before: {
              content: "before:content['']",
              display: "before:block",
              bg: "before:bg-blue-gray-500",
              width: "before:w-7",
              height: "before:h-7",
              borderRadius: "before:rounded-full",
              position: "before:absolute",
              top: "before:top-2/4",
              left: "before:left-2/4",
              transform: "before:-translate-y-2/4 before:-translate-x-2/4",
              opacity: "before:opacity-0 hover:before:opacity-10",
              transition: "before:transition-opacity",
            },
          },
          label: {
            color: "text-gray-700",
            fontWeight: "font-light",
            userSelect: "select-none",
            cursor: "cursor-pointer",
            mt: "mt-px",
          },
          icon: {
            position: "absolute",
            top: "top-2/4",
            left: "left-2/4",
            translate: "-translate-y-2/4 -translate-x-2/4",
            pointerEvents: "pointer-events-none",
            opacity: "opacity-0 peer-checked:opacity-100",
            transition: "transition-opacity",
          },
          disabled: {
            opacity: "opacity-50",
            pointerEvents: "pointer-events-none",
          },
        },
        colors: {
          "blue-gray": {
            color: "text-blue-gray-500",
            border: "checked:border-blue-gray-500",
            before: "checked:before:bg-blue-gray-500",
          },
          gray: {
            color: "text-gray-500",
            border: "checked:border-gray-500",
            before: "checked:before:bg-gray-500",
          },
          brown: {
            color: "text-brown-500",
            border: "checked:border-brown-500",
            before: "checked:before:bg-brown-500",
          },
          "deep-orange": {
            color: "text-deep-orange-500",
            border: "checked:border-deep-orange-500",
            before: "checked:before:bg-deep-orange-500",
          },
          orange: {
            color: "text-orange-500",
            border: "checked:border-orange-500",
            before: "checked:before:bg-orange-500",
          },
          amber: {
            color: "text-amber-500",
            border: "checked:border-amber-500",
            before: "checked:before:bg-amber-500",
          },
          yellow: {
            color: "text-yellow-500",
            border: "checked:border-yellow-500",
            before: "checked:before:bg-yellow-500",
          },
          lime: {
            color: "text-lime-500",
            border: "checked:border-lime-500",
            before: "checked:before:bg-lime-500",
          },
          "light-green": {
            color: "text-light-green-500",
            border: "checked:border-light-green-500",
            before: "checked:before:bg-light-green-500",
          },
          green: {
            color: "text-green-500",
            border: "checked:border-green-500",
            before: "checked:before:bg-green-500",
          },
          teal: {
            color: "text-teal-500",
            border: "checked:border-teal-500",
            before: "checked:before:bg-teal-500",
          },
          cyan: {
            color: "text-cyan-500",
            border: "checked:border-cyan-500",
            before: "checked:before:bg-cyan-500",
          },
          "light-blue": {
            color: "text-light-blue-500",
            border: "checked:border-light-blue-500",
            before: "checked:before:bg-light-blue-500",
          },
          blue: {
            color: "text-blue-500",
            border: "checked:border-blue-500",
            before: "checked:before:bg-blue-500",
          },
          indigo: {
            color: "text-indigo-500",
            border: "checked:border-indigo-500",
            before: "checked:before:bg-indigo-500",
          },
          "deep-purple": {
            color: "text-deep-purple-500",
            border: "checked:border-deep-purple-500",
            before: "checked:before:bg-deep-purple-500",
          },
          purple: {
            color: "text-purple-500",
            border: "checked:border-purple-500",
            before: "checked:before:bg-purple-500",
          },
          pink: {
            color: "text-pink-500",
            border: "checked:border-pink-500",
            before: "checked:before:bg-pink-500",
          },
          red: {
            color: "text-red-500",
            border: "checked:border-red-500",
            before: "checked:before:bg-red-500",
          },
        },
      },
    },
  };

  return (
    <ThemeProvider value={nucleusTheme}>
      <App>{children}</App>
    </ThemeProvider>
  );
};

export default Providers;
