import { v4 as uuidv4 } from "uuid";

import type { SerializedRiskLevel, SerializedRiskLevelPost } from "./interfaces";

export class SerializedRiskLevelModel implements SerializedRiskLevel {
  id?: number;
  risk_level: string;
  priority: number;
  count: {
    last_revalidated: string;
    count: number;
  } | null;
  search: string;
  transformed_search?: string;
  color: string;
  description?: string;

  // Unique identifier for frontend operations
  uuid: string;

  constructor(riskLevel: SerializedRiskLevel | SerializedRiskLevelModel) {
    Object.assign(this, riskLevel);

    this.uuid = (riskLevel as SerializedRiskLevelModel).uuid ?? uuidv4();
  }

  toApi(): SerializedRiskLevelPost {
    return {
      id: this.id,
      risk_level: this.risk_level,
      priority: this.priority,
      search: this.search,
      transformed_search: this.transformed_search,
      color: this.color,
      description: this.description && this.description.length > 0 ? this.description : undefined,
    };
  }
}
