import React from "react";

import QuickStartDrawer from "./Portals/QuickStartDrawer";

function Portals() {
  return (
    <>
      <QuickStartDrawer />
    </>
  );
}

export default Portals;
