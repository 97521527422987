import React from "react";

import TablePagination from "@components/TablePagination";
import { useClientSorting, useClosestListingTableState } from "@features/listing";
import { useLoadingAnimationCleanup } from "@hooks/useLoadingAnimationCleanup";
import {
  type ColumnSizingState,
  type OnChangeFn,
  type PaginationState,
  type SortingState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import TableLayout from "./TableLayout";

const TableInstance = () => {
  const {
    listingTableSnap: {
      results,
      columnsConfiguration,
      isFetching,
      isEnabled,
      recordsType,
      displayFeedsRow: showExtraRow,
      tableMaxHeight,
      totalPages,
    },
    listingTableState,
  } = useClosestListingTableState();

  const tableState = listingTableState.getState();

  useLoadingAnimationCleanup(); // ? is thats a correct place for this?

  const [canHandleSorting, appliedSorting] = useClientSorting(tableState.sorting, recordsType);

  // sync the table state with the filters
  const handlePaginationChange: OnChangeFn<PaginationState> = (updater: (old: PaginationState) => PaginationState) => {
    const next = updater({
      pageIndex: tableState.page,
      pageSize: tableState.perPage,
    });

    listingTableState.updatePagination(next.pageIndex, next.pageSize);
    return next;
  };

  const handleColumnSizingChange: OnChangeFn<ColumnSizingState> = (
    updater: (old: ColumnSizingState) => ColumnSizingState,
  ) => {
    const next = updater(tableState.columnSizing);

    listingTableState.updateColumnSizing(next);
  };

  const handleSortingChange: OnChangeFn<SortingState> = (updater: (old: SortingState) => SortingState) => {
    if (!canHandleSorting) {
      return;
    }

    const next = updater([...tableState.sorting]);

    listingTableState.updateSorting(next);
  };

  const tableInstance = useReactTable<any>({
    data: isEnabled && results ? results : [],
    columns: columnsConfiguration,
    getCoreRowModel: getCoreRowModel(),

    // Resizing
    enableColumnResizing: true,
    columnResizeMode: "onEnd",
    onColumnSizingChange: handleColumnSizingChange,

    // Sorting
    onSortingChange: handleSortingChange,
    enableSorting: canHandleSorting,

    // Pagination control
    manualPagination: true,
    pageCount: totalPages,
    onPaginationChange: handlePaginationChange,
    state: {
      sorting: appliedSorting as SortingState,
      columnSizing: tableState.columnSizing,
      pagination: {
        pageIndex: tableState.page,
        pageSize: tableState.perPage,
      },
    },
  });

  return (
    <div className="flex flex-col flex-1 h-full">
      <TablePagination table={tableInstance} />
      <TableLayout
        table={tableInstance}
        isFetching={isFetching}
        showExtraRow={showExtraRow}
        tableMaxHeight={tableMaxHeight}
      />
      <TablePagination table={tableInstance} showSizeSelector={false} />
    </div>
  );
};

export default TableInstance;
