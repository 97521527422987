import { useOrganizationConfiguration } from "@hooks/useOrganizationsConfiguration";
import { type UseSuspenseQueryOptions, useSuspenseQuery } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";

const fetchSiteSettings = () => {
  return fetchWithSession("/site_settings/");
};

export interface SiteSettingsResponse {
  is_watch_searches_enabled: boolean;
  is_malware_listing_enabled: boolean;
  is_field_reference_enabled: boolean;
  default_support_email: string;
  documentation_url?: string;
  support_url?: string;
}

function useSiteSettings(options?: Partial<UseSuspenseQueryOptions<SiteSettingsResponse>>) {
  return useSuspenseQuery<SiteSettingsResponse>({
    queryKey: ["site_settings"],
    queryFn: fetchSiteSettings,
    staleTime: 5 * 60 * 1000,
    ...options,
  });
}

function useGlobalPinnedSearches() {
  const { data } = useOrganizationConfiguration();

  return data?.global_pinned_searches || [];
}

function useDefaultVulnerabilityStatuses() {
  const { data } = useOrganizationConfiguration();

  return (data?.default_cve_statuses || []).map((status, index) => ({
    ...status,
    order: index,
  }));
}

function useSupportEmail() {
  const { data } = useSiteSettings();

  return data?.default_support_email;
}

export default useSiteSettings;
export { useGlobalPinnedSearches, useSupportEmail, useDefaultVulnerabilityStatuses };
