import type React from "react";
import { useEffect, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement("div");
  wrapperElement.setAttribute("id", wrapperId);
  document.body.appendChild(wrapperElement);

  return wrapperElement;
}

// Rendering a React component into a DOM element that is not a direct child of the root React element. This way we are able to render multiple React widgets in our Django template.
function TemplateSlot({
  children,
  slotID,
  keepContent = false,
  classNames,
  keepClasses = false,
}: {
  children?: React.ReactNode;
  slotID: string;
  keepContent?: boolean;
  classNames?: string;
  keepClasses?: boolean;
}) {
  const [wrapperElement, setWrapperElement] = useState<any>();

  // When using useLayoutEffect, it seems that the scheduled effect will run synchronously after all DOM mutations and before the browser has a chance to paint. This way we avoid the flickering of content when template placeholder is replaced with react component.
  // Back to useEffect now since layoutEffect fails in first login for some reason. (TopStatsPanel component, 02/01/2023)
  useEffect(() => {
    const element = document.getElementById(slotID);
    if (!element) {
      return;
    }

    if (!keepContent) {
      element.innerHTML = ""; // replacing template content with react component, so we can use original template for displaying loading initial state

      // clear classes from template element
      if (!keepClasses) {
        element.classList.remove(...(element.classList as any));
      }

      if (classNames) {
        classNames.split(" ").forEach((className) => {
          element.classList.add(className);
        });
      }
    }

    if (element) {
      element.classList.remove("loading-animation"); // removing loading animation class from template element
      setWrapperElement(element);
    }

    return () => {
      setWrapperElement(null);
    };
  }, [slotID]);

  // wrapperElement state will be null on the very first render or if slot wasnt found
  if (!wrapperElement) {
    return null;
  }

  return createPortal(children, wrapperElement, slotID);
}

export default TemplateSlot;
