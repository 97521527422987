import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import { type QueryKey, type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";

import type { AbstractRecordsApiQueryResponse } from "../ListingTableState";

export interface ThreatsListingApiResponse extends AbstractRecordsApiQueryResponse {
  total_count: number;
  bookmarked_count: number;
  meta: {
    total_pages: number;
    page: number;
    per_page: number;
  };
  results: FrontendSerializedThreat[];
  sorting: string[]; // Query returns applied ordering here, since it may come from different sources
}

export const fetchListingThreats = async (url: string | null, signal?: AbortSignal) => {
  if (!url) {
    return Promise.resolve([]);
  }

  try {
    const data = await fetchWithSession(url, {
      signal: signal,
    });

    if (data?.detail) {
      return Promise.reject(new Error(data.detail));
    }
    if (typeof data === "string") {
      // djangoQL errors are sent as a simple string
      return Promise.reject(new Error(data));
    }

    return Promise.resolve(data);
  } catch (error: any) {
    // do not throw error if request was aborted by user
    if (error.name === "AbortError") {
      return Promise.reject(new Error("Request aborted by user"));
    }

    if (error instanceof Error) {
      return Promise.reject(error);
    }

    return Promise.reject(new Error("Unknown error"));
  }
};

const ThreatsListingQueryKey = "threatsListing";

function useThreatsListing<T extends AbstractRecordsApiQueryResponse>(
  listingUrl: string | null,
  options: Partial<UseQueryOptions<T, Error, T, QueryKey>> = {},
) {
  return useQuery<T, Error, T>({
    queryKey: [ThreatsListingQueryKey, listingUrl],
    queryFn: ({ signal }) => fetchListingThreats(listingUrl, signal),
    throwOnError: false,
    retry: 0,
    placeholderData: keepPreviousData,
    staleTime: 5 * 1000, // 5 seconds just to avoid flickering of some UI elements
    enabled: !!listingUrl,
    ...options,
  });
}

export { useThreatsListing, ThreatsListingQueryKey };
