import { Tag } from "@components/Tag";
import Tooltip from "@components/Tooltip";
import type { SerializedTag } from "@features/tags";
import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const LIMIT_TAGS_TO = 10;

const renderTag = (tag: SerializedTag, index: number) => (
  <Tag key={`${tag.value}-${index}`} tagValue={tag.value} isGlobal={!!tag.is_global} isLoading={false} />
);

const VipTagsRenderer = ({ getValue }: CellContext<FrontendSerializedThreat, SerializedTag[]>) => {
  const values = getValue() || [];

  const limitedTags = values.slice(0, LIMIT_TAGS_TO);
  const remainingTags = values.slice(LIMIT_TAGS_TO);

  const fullView = <div className="flex flex-wrap gap-1">{values.map(renderTag)}</div>;

  if (values.length > LIMIT_TAGS_TO) {
    return (
      <div className="flex flex-col">
        <div className="flex flex-wrap gap-1">{limitedTags.map(renderTag)}</div>

        <Tooltip value={fullView}>
          <div className="cursor-progress mt-2 text-xs text-right text-gray-600">
            And {remainingTags.length} more...
          </div>
        </Tooltip>
      </div>
    );
  }

  return fullView;
};

export default VipTagsRenderer;
