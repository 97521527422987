import classNames from "classnames";
import type React from "react";

function RadialProgress({
  percent,
  children,
  color = "text-nucleus",
  size = "w-8 h-8",
}: {
  percent: number;
  children?: React.ReactNode;
  color?: string;
  size?: string;
}) {
  const circumference = 2 * Math.PI * 13;
  const offset = circumference - (percent / 100) * circumference;

  return (
    <div className="relative block">
      <svg viewBox="0 0 32 32" fill="none" strokeWidth="5" className={classNames("relative z-0 overflow-hidden", size)}>
        <circle
          cx="16"
          cy="16"
          r="13"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset="0"
          transform="rotate(-90 16 16)"
          strokeLinecap="round"
          className="h-full text-gray-300 stroke-current"
        />

        <circle
          cx="16"
          cy="16"
          r="13"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={`${offset}`}
          transform="rotate(-90 16 16)"
          strokeLinecap="round"
          className={`h-full stroke-current transition-all !duration-500 ${color}`}
        />
      </svg>
      <span className="absolute inset-0 flex items-center justify-center text-[9px] font-bold tracking-widest">
        {children}
      </span>
    </div>
  );
}

export default RadialProgress;
