import { useEffect } from "react";

export function useLoadingAnimationCleanup() {
  useEffect(() => {
    const animateOnLoadElements = document.getElementsByClassName("loading-animation");

    for (let i = 0; i < animateOnLoadElements.length; i++) {
      const element = animateOnLoadElements[i];

      element.classList.remove("loading-animation");
    }
  }, []);
}
