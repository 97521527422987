import useGlobalVariables from "@hooks/useGlobalVariables";
import type SerializedWatchedSearch from "@interfaces/SerializedWatchedSearch";
import { useWatchedSearchesFiltersValue } from "@pages/watching/WatchedSearches/watchedSearchesFilter";
import { type UseSuspenseQueryOptions, useSuspenseQuery } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";

const watchedSearchesUrlEndpoint = "/api/v1/watch_searches/";

type WatchedSearchesApiResponse = SerializedWatchedSearch[];

const WATCHED_SEARCHES_QUERY_KEY = (currentUserID: number) => ["watchedSearches", currentUserID];

async function fetchWatchedSearches(): Promise<WatchedSearchesApiResponse> {
  return fetchWithSession(watchedSearchesUrlEndpoint, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  });
}

function useWatchedSearches(options: Partial<UseSuspenseQueryOptions<WatchedSearchesApiResponse>> = {}) {
  const { search_value } = useWatchedSearchesFiltersValue();
  const { currentUserID } = useGlobalVariables();

  return useSuspenseQuery<WatchedSearchesApiResponse, Error, WatchedSearchesApiResponse>({
    queryKey: WATCHED_SEARCHES_QUERY_KEY(currentUserID),
    queryFn: fetchWatchedSearches,
    ...options,
    staleTime: 60 * 1000,
    select: (data) => {
      if (search_value) {
        return data.filter((watchedSearch) =>
          watchedSearch.saved_search.name.toLowerCase().includes(search_value.toLowerCase()),
        );
      }
      return data;
    },
  });
}

export default useWatchedSearches;
export type { SerializedWatchedSearch };
export { WATCHED_SEARCHES_QUERY_KEY };
