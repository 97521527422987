import SelectionFilter from "@components/tableElements/SelectionFilter";
import { getSeverityString } from "@features/watchedSearches/severity";
import useWatchedSearches from "@features/watchedSearches/useWatchedSearches";
import type SerializedAlert from "@interfaces/SerializedAlert";
import DateRangeFilter from "@pages/alerts/AlertsDetails/Filters/DateRangeFilter";
import type { CellContext } from "@tanstack/react-table";
import { DateTime } from "luxon";
import React, { useCallback, useMemo } from "react";
import { updateAlertsTableStateFilterValue, useAlertsTableFiltersValue } from "../state/alertsTable";
import type { AlertsApiResponse } from "./useAlerts";
import { ActionsCell, type AlertsTableColumnDef, CheckBoxAllCell, CheckBoxCell } from "./useAlertsTable";

export function useAlertsColumns(filterBoundaries?: AlertsApiResponse["filter_boundaries"]): AlertsTableColumnDef[] {
  const filtersSnap = useAlertsTableFiltersValue();
  const updateNameFilter = useCallback((value: string[]) => {
    updateAlertsTableStateFilterValue("object_id", value);
  }, []);

  const updateSeverityFilter = useCallback((value: string[]) => {
    updateAlertsTableStateFilterValue("severity", value);
  }, []);

  const { data: watched_searches } = useWatchedSearches({
    suspense: true,
  });
  const nameOptions = watched_searches.map((monitored_search) => ({
    label: monitored_search.saved_search.name,
    value: monitored_search.id.toString(),
  }));

  // biome-ignore lint/correctness/useExhaustiveDependencies: <We don't want to include the filters in the dependency array because it will cause the filter to re-render for no reason>
  return useMemo(
    () => [
      // selection checkbox
      {
        id: "select",
        HeaderFilter: CheckBoxAllCell,
        cell: CheckBoxCell,
        minSize: 48,
      },
      {
        header: "Date Triggered",
        accessorKey: "created_at",
        minSize: 210,
        cell: (context: CellContext<SerializedAlert, string>) => {
          const value = context.getValue();
          const date = DateTime.fromISO(value);
          return (
            <div className="flex flex-col">
              <span>{date.toFormat("MM/dd/yyyy hh:mm:ss a")}</span>
              <span>({date.toRelative()})</span>
            </div>
          );
        },
        HeaderFilter: () => (
          <DateRangeFilter
            accessorKey="created_at"
            header="Date Triggered"
            options={{
              is_enabled: !!(filterBoundaries?.min_date && filterBoundaries?.max_date),
              min_date: filterBoundaries?.min_date,
              max_date: filterBoundaries?.max_date,
            }}
          />
        ),
      },
      {
        header: "Search Name",
        accessorKey: "object_id",
        id: "object_id",
        cell: (props) => {
          const value = props.row.original.content_object?.saved_search?.name;
          return <span>{value ?? "N/A"}</span>;
        },
        HeaderFilter: () => (
          <SelectionFilter
            accessorKey="object_id"
            header="Search Name"
            filteredOptions={nameOptions}
            options={nameOptions}
            currentValue={filtersSnap.object_id.map(String)} // Convert number array to string array
            updateFilterState={updateNameFilter}
          />
        ),
      },
      {
        header: "Severity",
        accessorKey: "severity",
        cell: (context: CellContext<SerializedAlert, string>) => {
          const value = context.getValue();

          return <span className="flex items-center justify-center gap-1">{getSeverityString(+value)}</span>;
        },
        HeaderFilter: () => {
          const severityOptions = [1, 2, 3, 4, 5].map((num) => ({
            label: getSeverityString(num),
            value: num.toString(),
          }));

          return (
            <SelectionFilter
              accessorKey="severity"
              header="Severity"
              filteredOptions={severityOptions}
              options={severityOptions}
              currentValue={filtersSnap.severity.map(String)}
              updateFilterState={updateSeverityFilter}
            />
          );
        },
      },
      {
        header: "Alert Type",
        accessorKey: "content_type",
        cell: (_context: CellContext<SerializedAlert, SerializedAlert["content_type"]>) => {
          // TODO: this was supposed to be hardcoded, but the content_type is not always stable db from db. Since we only have 1 content type for now, we can hardcode it. But later we need to find way.
          return <span>Monitored Search</span>;
        },
      },
      {
        header: "# of CVEs",
        accessorKey: "details",
        cell: (context: CellContext<SerializedAlert, SerializedAlert["details"]>) => {
          const details = context.getValue();
          const value = details?.reduce((acc, curr) => {
            return acc + curr.paged_data.length;
          }, 0);

          return <span>{value}</span>;
        },
      },
      {
        header: "Actions",
        id: "actions",
        cell: ActionsCell,
      },
    ],
    [],
  );
}
