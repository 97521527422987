import { type AlertsTableState, updateAlertsTableStateFilterValue } from "@features/alerts/state/alertsTable";
import classNames from "classnames";
import React from "react";

export function ResetButton({
  filter,
  className,
  defaultFilterValue = "",
}: {
  filter: keyof AlertsTableState["filters"];
  className?: string;
  defaultFilterValue?: any;
}) {
  const onResetClickHandler = (e: { stopPropagation: () => void; preventDefault: () => void }) => {
    updateAlertsTableStateFilterValue(filter, defaultFilterValue);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div className={classNames("right-2 absolute top-0 flex items-center h-full opacity-60", className)}>
      <i
        title="Reset filter"
        className="fa fa-delete-left hover:text-gray-900 text-gray-500 transition-all cursor-pointer"
        onClick={onResetClickHandler}
      />
    </div>
  );
}
