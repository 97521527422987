import React, { useEffect, Fragment } from "react";

import { Transition } from "@headlessui/react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

const Popover = ({
  Trigger,
  children,
  isOpen = false,
  setOpen,
  triggerProps = {},
  popoverProps = {},
  container,
}: {
  Trigger: React.ForwardRefExoticComponent<
    {
      isOpen: boolean;
    } & React.RefAttributes<HTMLButtonElement> &
      Record<string, any>
  >;
  children: React.ReactNode;
  isOpen?: boolean;
  setOpen?: (isOpen: boolean) => void;
  triggerProps?: React.ComponentPropsWithoutRef<any>;
  popoverProps?: PopoverPrimitive.PopoverContentProps;
  container?: HTMLElement;
}) => {
  // State could be handled by a parent component also
  const [_isOpen, _setOpen] = React.useState(isOpen);
  const handleChangeOpen = (newStatus: boolean) => {
    if (setOpen) {
      setOpen(newStatus);
    } else {
      _setOpen(newStatus);
    }
  };

  useEffect(() => {
    if (isOpen !== _isOpen) {
      _setOpen(isOpen);
    }
  }, [isOpen]);

  const handleOnOpenChange = () => {
    handleChangeOpen(!_isOpen);
  };

  const handleOnOpenAutoFocus = (event: Event) => event.preventDefault();
  return (
    <div className="relative">
      <PopoverPrimitive.Root open={_isOpen} onOpenChange={handleOnOpenChange} modal={false}>
        <PopoverPrimitive.Trigger asChild>
          <Trigger isOpen={_isOpen} {...triggerProps} />
        </PopoverPrimitive.Trigger>
        <PopoverPrimitive.Portal forceMount container={container}>
          <Transition.Root show={_isOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-in duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <PopoverPrimitive.Content
                hideWhenDetached={true}
                align="start"
                sideOffset={10}
                forceMount
                onOpenAutoFocus={handleOnOpenAutoFocus}
                {...popoverProps}
              >
                <div className="ring-1 ring-blue-500 ring-opacity-5 overflow-visible rounded-lg shadow-lg">
                  <div className="relative p-2 bg-white">{children}</div>
                </div>
              </PopoverPrimitive.Content>
            </Transition.Child>
          </Transition.Root>
        </PopoverPrimitive.Portal>
      </PopoverPrimitive.Root>
    </div>
  );
};

Popover.Close = PopoverPrimitive.Close;

export default Popover;
