import React from "react";

import FeedIcon from "@components/FeedIcon";

export function headerRenderer(header: any) {
  // fieldPrefix is source: cisa, greynoise, mandiant, etc.
  const fieldPrefix = header.column.id.split("_")[0];

  // display icon based on fieldPrefix and field name generated from id without prefix
  return (
    <>
      <span>
        <FeedIcon fieldPrefix={fieldPrefix} />
      </span>

      <span className="whitespace-nowrap truncate">
        {header.column.id.replace(`${fieldPrefix}_`, "").replace(/_/g, " ")}
      </span>
    </>
  );
}
