import * as Sentry from "@sentry/react";
import { useQueryClient, useQueryErrorResetBoundary } from "@tanstack/react-query";

import Button from "./Button";

const QueryErrorBoundary = ({
  children,
  fallbackRender,
  showRetryButton = true,
}: { children: JSX.Element; fallbackRender?: () => JSX.Element; showRetryButton?: boolean }) => {
  const { reset } = useQueryErrorResetBoundary();
  const queryClient = useQueryClient();

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => {
        if (fallbackRender) {
          return fallbackRender();
        }

        return (
          <div className="relative px-4 py-3 m-4 text-red-700 bg-red-100 rounded border border-red-400">
            <strong className="block font-bold">An Unexpected Error Occurred</strong>
            <span className="block sm:inline">We encountered an issue: {error.message}</span>
            {showRetryButton && (
              <div className="my-2">
                <Button
                  color="secondary"
                  onClick={() => {
                    resetError();
                    reset(); // Reset React Query boundary
                    queryClient.clear(); // Clear query cache
                  }}
                >
                  Retry Operation
                </Button>
              </div>
            )}
            {!showRetryButton && (
              <div className="my-2">
                <span>Our technical team has been notified and is working to resolve the issue.</span>
              </div>
            )}
          </div>
        );
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export { QueryErrorBoundary };
