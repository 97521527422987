import useGlobalVariables from "@hooks/useGlobalVariables";

import { useQuery } from "@tanstack/react-query";
import { UNSEEN_ALERTS_CACHE_KEY, fetchAlerts } from "./hooks/useAlerts";

function useUnseenAlerts() {
  const { currentUserID } = useGlobalVariables();

  return useQuery({
    queryKey: UNSEEN_ALERTS_CACHE_KEY(currentUserID),
    queryFn: () => fetchAlerts<number>(undefined, true),
    staleTime: 1 * 60 * 1000, // 1 minute
  });
}

function useUnseenAlertsCount() {
  const { data: unseenAlertsCount } = useUnseenAlerts();
  return unseenAlertsCount;
}

export default useUnseenAlertsCount;
