import type SerializedProfile from "@interfaces/SerializedProfile";
import type { IntrospectionsResponse } from "@queries/useIntrospections";
import { useMemo } from "react";

const contextElementID = "page-context"; // exists in main templates scripts.html

interface CommonPageContext {
  introspections: IntrospectionsResponse;
  userprofile: SerializedProfile;
  is_impersonated: boolean;
}

function getPageContext<T extends CommonPageContext = CommonPageContext>(): T {
  const pageContextElement = document.getElementById(contextElementID);
  if (!pageContextElement) {
    throw new Error("Page context element not found");
  }

  const pageContext = pageContextElement.textContent;
  if (pageContext) {
    try {
      const parsedPageContext = JSON.parse(pageContext) as T;
      return parsedPageContext;
    } catch (e) {
      throw new Error("Failed to parse page context");
    }
  }

  throw new Error("Page context is empty");
}

function usePageContext<T extends CommonPageContext = CommonPageContext>(): T {
  return useMemo(() => getPageContext<T>(), []);
}

export { getPageContext, usePageContext };
export type { CommonPageContext };