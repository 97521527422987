import React from "react";

import Tooltip from "@components/Tooltip";
import type { ExploitDBExploit, FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

/**
 * 
interface ExploitDBExploit {
  cve_id: string;
  exploitdb_author: string;
  exploitdb_date_publised: string;
  exploitdb_date_updated: string;
  exploitdb_filename: string;
  exploitdb_source_url: string | null;
  exploitdb_url: string | null;
}
 */
const ExploitdbExploitsRenderer: (context: CellContext<FrontendSerializedThreat, ExploitDBExploit[]>) => JSX.Element = (
  context,
) => {
  const exploits = context.getValue();

  if (exploits?.length > 0) {
    // exploits contains cve_id which can be linked, but we also need some way to show the rest of the information, not just the links
    return (
      <div>
        {exploits.map((exploit, index) => {
          const filename = exploit.exploitdb_filename; // in format of exploits/multiple/local/51261.txt or just 51261.txt, we need to extract the number from it for the url
          const exploitId = filename.split("/").pop()?.split(".")[0];
          const exploitDBUrl = `https://www.exploit-db.com/exploits/${exploitId}`;
          return (
            <div key={`exploitdb_exploits_${index}`}>
              <Tooltip
                value={
                  <div>
                    <div className="text-xs text-gray-600">Author: {exploit.exploitdb_author}</div>
                    <div className="text-xs text-gray-600">Date Published: {exploit.exploitdb_date_publised}</div>
                    <div className="text-xs text-gray-600">Date Updated: {exploit.exploitdb_date_updated}</div>
                    <div className="text-xs text-gray-600">Filename: {exploit.exploitdb_filename}</div>
                    <div className="text-xs text-gray-600">Source URL: {exploit.exploitdb_source_url || "N/A"}</div>
                    <div className="text-xs text-gray-600">
                      CVE:{" "}
                      {/* <a
                        href={`/v/${exploit.cve_id}`}
                        target="_blank"
                        className="text-blue-600"
                      > */}
                      {exploit.cve_id}
                      {/* </a> */}
                    </div>
                  </div>
                }
              >
                <a
                  className="inline-flex items-center font-semibold transition-all ease-nav-brand text-slate-600 hover:text-blue-700"
                  href={exploitDBUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {exploitId}
                  <i className="ml-1 text-xs fas fa-external-link-alt" />
                </a>
              </Tooltip>
            </div>
          );
        })}
      </div>
    );
  }

  return <div>-</div>;
};

export default ExploitdbExploitsRenderer;
